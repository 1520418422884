.image-container {
    max-width: 100%;
    height: auto;
    overflow: hidden;
}
  
.responsive-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  @media (max-width: 768px) {
    .image-container {
      max-width: 100%;
      height: auto;
    }
  }
  